import api from 'services/api';
import { refreshDashboard } from 'Screens/Dashboard/actions/Actions';
import { getWorkspaces } from 'Screens/Workspaces/actions/Actions';
import { newGetVulns } from 'store/Manage/filterActions';
import isEmpty from 'lodash/isEmpty';
import { selectCommands, selectUnfinishedCommands } from 'store/FileUploaderContext/selector';

export const HIDE_FILE_UPLOAD_CONTEXT = 'HIDE_FILE_UPLOAD_CONTEXT';
export const ADD_COMMAND = 'ADD_COMMAND';
export const UPDATE_COMMAND_LIST_STATUS = 'UPDATE_COMMAND_LIST_STATUS';

export function hideUploadContextMenu () {
  return (dispatch) => {
    dispatch({ type: HIDE_FILE_UPLOAD_CONTEXT });
  };
}

export function updateCommandsStatus () {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const commands = selectCommands(state);
      const unfinishedCommands = selectUnfinishedCommands(state);

      const promises = unfinishedCommands.map((c) => api.workspace.getReportStatus(c.command_id));
      const results = await Promise.all(promises);

      const commandListUpdated = commands.map((command) => {
        if (command.finished) return command;
        else {
          const commandStatus = results.find((updated) => updated._id === command.command_id);
          const finished = commandStatus.duration !== 'In progress';
          const error = commandStatus.duration === 'Error';
          return ({ ...command, finished, error });
        }
      });

      if (isEmpty(promises)) {
        if (window.location.href.includes('/manage')) dispatch(newGetVulns());
        else if (window.location.href.includes('/feed')) dispatch(refreshDashboard());
        dispatch(getWorkspaces());
      }

      return dispatch({ type: UPDATE_COMMAND_LIST_STATUS, data: commandListUpdated });
    } catch (error) {
      return dispatch({ type: '', error });
    }
  };
}


import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  width: 406px;
  max-height: 350px;
  border-radius: 2px;
  box-shadow: 2px 2px 30px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  overflow-y: auto;
  padding: 18px 0;
  position: absolute;
  z-index: 10;
  ${({ customStyles }) => customStyles && `
    width: 100%;
    height: 100%;
    max-height: 100px;
    overflow: auto;
    position: relative;
    box-shadow: none;
    padding: 0;
  `}
`;
Wrapper.displayName = 'Wrapper';

export const DataItem = styled.div`
  font-size: ${(props) => (`${props.customStyles ? '14.5px' : '13px'}`)};
  color: #1c2243;
  cursor: pointer;
  padding: ${(props) => (`${props.customStyles ? '10px' : '7px 5px 7px 20px'}`)};
  border-radius: 3px;
  user-select: none;
  background-color: ${(props) => (`${props.isActive ? colors.iceBlue : ''}`)};
  font-weight: ${(props) => (`${props.isActive ? 600 : 400}`)};
`;
DataItem.displayName = 'DataItem';

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import EditButton from 'Common/Components/EditButton';
import { selectHostsSelected } from 'store/Host/selectors';
import { selectShowHostDetail } from 'store/HostDetail/selectors';
import { toggleModalEditCreate } from 'store/Host/actions';

const EditHostButton = () => {
  const hostsSelected = useSelector(selectHostsSelected);
  const isHostDetailVisible = useSelector(selectShowHostDetail);
  const dispatch = useDispatch();

  return (
    <EditButton editFunction={ (host) => dispatch(toggleModalEditCreate(true, host)) } selectedEntities={ hostsSelected } display={ hostsSelected.length === 1 && !isHostDetailVisible } />
  );
};

export default EditHostButton;

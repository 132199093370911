import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: ${colors.grey19};
  padding: 12px 20px 13px 20px;
  margin-bottom: 10px;
  border-radius: 2px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.03);
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  & > *:not(:last-child) {
    /* margin-right: auto; */
    border-right: 1px dashed #c3c3c3;
    height: 23px;
    padding-right: 13.5px;
    margin-right: 13.5px;
  }
  & > :first-child {
    /* padding-right: 30px; */
  }
  /* & > :last-child {
    width: 100%;
  } */
`;
Wrapper.displayName = 'Wrapper';

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
FieldContainer.displayName = 'FieldContainer';

export const FieldName = styled.div`
  color: ${colors.grey12};
  flex: 0 1 0;
  font-size: 13.5px;
  font-weight: 500;
`;
FieldName.displayName = 'FieldName';

export const FieldValue = styled.div`
  padding-left: 5px;
  color: ${colors.white1};
  font-size: 13.5px;
  font-weight: 300;
  vertical-align: sub;
`;
FieldValue.displayName = 'FieldValue';

/* eslint-disable no-nested-ternary */
/* eslint-disable no-negated-condition */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import TimeAgo from 'javascript-time-ago';
// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en';
import Switch from 'react-switch';
import colors from 'Styles/colors';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'Common/Components/Skeleton';
import DateFormatter from 'Common/Components/DateFormatter';
import {
  ColWorkspaceDescription, ColWorkspaceNumeric, SwitchContainer, ColWorkspaceStatus, ColWorkspaceBetweenTime
} from './styled';
import Name from './Name';
import ImportanceColumn from './ImportanceColumn';

TimeAgo.addLocale(en);

export default function getColumns (enableDisable, changeReadOnly, handleSingleClick, isFetching) {
  const columns = [{
    Header: () => <FormattedMessage id="workspaces.table.columns.name" />,
    id: 'name',
    resizable: false,
    Cell: (rowInfo) => <Name isFetching={ isFetching } ws={ rowInfo.original } />,
    getProps: () => ({ 'column-type': 'name' })
  },
  {
    Header: () => <FormattedMessage id="workspaces.table.columns.important" />,
    id: 'importance',
    accessor: 'importance',
    resizable: false,
    width: 90,
    Cell: (rowInfo) => ((isFetching) ? <Skeleton /> : <ImportanceColumn ws={ rowInfo.original } />)
  },
  {
    Header: () => <FormattedMessage id="workspaces.table.columns.description" />,
    id: 'description',
    sortable: false,
    resizable: false,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColWorkspaceDescription active={ rowInfo.original.active }>
            { rowInfo.original.description }
          </ColWorkspaceDescription>
          )
    ),
    getProps: () => ({ 'column-type': 'description' })
  }, {
    Header: () => <FormattedMessage id="workspaces.table.columns.created" />,
    resizable: false,
    id: 'create_date',
    maxWidth: 160,
    Cell: (rowInfo) => (isFetching ? <Skeleton /> : <DateFormatter date={ rowInfo.original.create_date } />)
  }, {
    Header: () => <FormattedMessage id="workspaces.table.columns.vulnerabilidades" />,
    resizable: false,
    id: 'vulnerabilities',
    maxWidth: 140,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColWorkspaceNumeric active={ rowInfo.original.active }>
            { rowInfo.original.stats.total_vulns }
          </ColWorkspaceNumeric>
          )
    ),
    getProps: () => ({ 'column-type': 'vulnerabilities' })
  }, {
    Header: () => <FormattedMessage id="workspaces.table.columns.assets" />,
    resizable: false,
    id: 'hosts',
    sortable: false,
    maxWidth: 80,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColWorkspaceNumeric active={ rowInfo.original.active }>
            { rowInfo.original.stats.hosts }
          </ColWorkspaceNumeric>
          )
    ),
    getProps: () => ({ 'column-type': 'hosts' })
  }, {
    Header: () => <FormattedMessage id="workspaces.table.columns.services" />,
    resizable: false,
    id: 'services',
    sortable: false,
    maxWidth: 90,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColWorkspaceNumeric active={ rowInfo.original.active }>
            { rowInfo.original.stats.services }
          </ColWorkspaceNumeric>
          )
    ),
    getProps: () => ({ 'column-type': 'services' })
  }, {
    Header: () => <FormattedMessage id="workspaces.table.columns.datesBetween" />,
    resizable: false,
    id: 'range_dates',
    maxWidth: 230,
    sortable: false,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColWorkspaceBetweenTime>
            {`${rowInfo.original.duration.start_date ? new Date(rowInfo.original.duration.start_date).toString().substring(0, 15) : ''}${rowInfo.original.duration.start_date && rowInfo.original.duration.end_date ? ' - ' : ''}${rowInfo.original.duration.end_date ? new Date(rowInfo.original.duration.end_date).toString().substring(0, 15) : ''}`}
          </ColWorkspaceBetweenTime>
          )
    ),
    getProps: () => ({ 'column-type': 'range_dates' })
  }, {
    Header: () => <FormattedMessage id="workspaces.table.columns.status" />,
    maxWidth: 90,
    resizable: false,
    filterable: false,
    id: 'active',
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <SwitchContainer checked={ rowInfo.original.active }>
            <Switch
              checked={ rowInfo.original.active }
              onChange={ (checked, e) => { e.stopPropagation(); enableDisable(rowInfo.original.name, !checked); } }
              onColor={ colors.green4 }
              offColor={ colors.grey14 }
              onHandleColor={ colors.green3 }
              offHandleColor={ colors.grey15 }
              boxShadow="0 0 4px 0 rgba(83, 83, 83, 0.5)"
              activeBoxShadow=""
              height={ 14 }
              width={ 39 }
              handleDiameter={ 21 }
              uncheckedIcon={ false }
              checkedIcon={ false }
            />
          </SwitchContainer>
          )
    ),
    getProps: () => ({ 'column-type': 'active' })
  }, {
    Header: () => <FormattedMessage id="workspaces.table.columns.permissions" />,
    id: 'readonly',
    filterable: false,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColWorkspaceStatus readonly={ rowInfo.original.readonly } onClick={ (e) => { if (rowInfo.original.active) { e.stopPropagation(); changeReadOnly(rowInfo.original.name, !rowInfo.original.readonly); } } } active={ rowInfo.original.active }>
            {!rowInfo.original.active ? <FormattedMessage id="workspaces.table.columns.inactive" /> : (rowInfo.original.readonly ? <FormattedMessage id="workspaces.table.columns.readOnly" /> : <FormattedMessage id="workspaces.table.columns.editable" />)}
          </ColWorkspaceStatus>
          )
    ),
    resizable: false,
    maxWidth: 120,
    getProps: () => ({ 'column-type': 'status' })
  }];
  return columns;
}

import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Wrapper, Content, Description, BoldDescription, FeatureImage, TitleWrapper, TitleBold
} from './styled';
import BlueButton from 'Common/Components/BlueButton/index';
import { URL_PRICING } from 'Common/Components/UpgradeLicense/index';

const UpgradeLicense = ({ image, description }) => {
  const intl = useIntl();
  const onClick = () => window.open(URL_PRICING);

  return (
    <Wrapper>
      <FeatureImage src={ image } />
      <Content>
        <TitleWrapper>
          <FormattedMessage id="upgradeLicense.sidebar.titleForMoreFeatures" values={ { b: (chunks) => <TitleBold children={ chunks } /> } } />
        </TitleWrapper>
        <Description>
          <FormattedMessage
            id={ description }
            values={ {
              b: (chunks) => <BoldDescription children={ chunks } />,
              br: <br />
            } }
          />
        </Description>
        <BlueButton label={ intl.formatMessage({ id: 'upgradeLicense.sidebar.upgradeNow' }) } onClick={ onClick } />
      </Content>
    </Wrapper>
  );
};

UpgradeLicense.propTypes = {
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired
};

export default UpgradeLicense;

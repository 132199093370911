import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  toggleModalEditCreate, updateHost
} from 'store/Host/actions';
import { Wrapper, HostIp, Edit } from './styled';
import Importance from 'Common/Components/Importance';
import { selectHostDetail } from 'store/HostDetail/selectors';

const Ip = () => {
  const dispatch = useDispatch();
  const host = useSelector(selectHostDetail);

  const callback = (importance) => dispatch(updateHost(host._id, { importance }));

  return (
    <Wrapper>
      <Importance importance={ host.importance } callback={ callback } />
      <HostIp>{host.ip}</HostIp>
      <Edit onClick={ () => dispatch(toggleModalEditCreate(true, host)) } />
    </Wrapper>
  );
};

Ip.propTypes = {
  host: PropTypes.instanceOf(Object).isRequired
};

export default Ip;

import styled from 'styled-components';

export const Wrapper = styled.div`
  
`;
Wrapper.displayName = 'Wrapper';

export const RedAster = styled.span`
  color: #ea3158;
  bottom: 12px;
  position: relative;
  left: 2px;
`;
RedAster.displayName = 'RedAster';

const defaultFields = {
  id: {
    visible: false, order: -1
  },
  asset: {
    visible: true, order: 1, locked: true
  },
  important: {
    visible: true, order: 2, locked: true
  },
  search: {
    visible: false, order: 3
  },
  os: {
    visible: true, order: 4
  },
  hostnames: {
    visible: true, order: 5
  },
  open_services: {
    visible: false, order: 6
  },
  vulns: {
    visible: true, order: 7
  },
  owned: {
    visible: true, order: 8
  },
  description: {
    visible: true, order: 9
  },
  mac: {
    visible: false, order: 10
  },
  owner: {
    visible: true, order: 11
  },
  creation_time: {
    visible: false, order: 12
  },
  severity_counts: {
    visible: true, order: 13
  },
  last_modified: {
    visible: true, order: 14
  },
  services: {
    visible: false, order: 15
  }
};

export default defaultFields;

import React from 'react';
import CheckBox from 'Common/Components/Checkbox/styled';
import { useDispatch } from 'react-redux';
import {
  changeReadOnly, enableDisable, selectWorkspace, updateWS, workspaceSelectedFromTable
} from 'Screens/Workspaces/actions/Actions';
import TimeAgo from 'react-timeago';
import get from 'lodash/get';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import {
  Wrapper, Title, Label, Text, FieldContainer, ReadOnlyContainer, ActiveContainer, LockIcon, UnlockIcon
} from './styled';
import Stats from '../Stats';
import Histogram from '../Histogram';
import Importance from 'Common/Components/Importance';

const WorkspaceItem = ({
  ws, selected
}) => {
  const dispatch = useDispatch();
  const updateDate = get(ws, 'update_date', null);
  const lastRunAgent = get(ws, 'last_run_agent_date', null);

  const handleSelectWorkspace = () => {
    dispatch(selectWorkspace({
      rowSelected: selected ? {} : ws,
      newRowsSelected: selected ? [] : [ws],
      lastSelected: -1,
      lastIndexForRange: -1
    }));
  };

  const handleSelect = () => (ws.active) && dispatch(workspaceSelectedFromTable(ws.name, get(ws, 'stats.total_vulns', 0)));

  const toggleActive = (e) => {
    e.stopPropagation();
    dispatch(enableDisable(ws.name, !e.target.checked));
  };

  const toggleReadOnly = (e, value) => {
    e.stopPropagation();
    dispatch(changeReadOnly(ws.name, !value));
  };

  const parseDate = (d) => {
    const today = moment();
    const isToday = moment(d).isSame(today, 'day');
    if (isToday) return 'Today';
    return moment(d).format('ddd D MMM YYYY');
  };

  const parsedDateRange = (ws) => {
    const startDate = get(ws, 'duration.start_date', null);
    const endDate = get(ws, 'duration.end_date', null);
    if (!startDate || !endDate) return 'No start or end date defined.';
    return `${parseDate(startDate)} - ${parseDate(endDate)}`;
  };

  return (
    <Wrapper selected={ selected } active={ ws.active } onClick={ handleSelectWorkspace }>
      <FieldContainer>
        <Importance importance={ ws.importance } callback={ (importance) => dispatch(updateWS(ws.name, { importance })) } />
        <ReadOnlyContainer>
          { ws.readonly ? <LockIcon onClick={ (e) => toggleReadOnly(e, true) } /> : <UnlockIcon onClick={ (e) => toggleReadOnly(e, false) } />}
          <Text>{ ws.readonly ? 'Read Only' : 'Editable' }</Text>
        </ReadOnlyContainer>
        <ActiveContainer>
          <CheckBox readOnly checked={ ws.active } onClick={ toggleActive } />
          <Text>{ ws.active ? 'Active' : 'Inactive' }</Text>
        </ActiveContainer>
      </FieldContainer>

      <Title onClick={ handleSelect }>{ ws.name }</Title>
      <FieldContainer>
        <Text>{ parsedDateRange(ws) }</Text>
      </FieldContainer>
      <FieldContainer>
        <Label>Description:</Label>
        <Text>{ ws.description }</Text>
      </FieldContainer>
      <Stats totalVulns={ ws.stats.total_vulns } totalAssets={ ws.stats.hosts } totalServices={ ws.stats.services } totalConfirmed={ ws.stats.confirmed_vulns } totalCritical={ ws.stats.critical_vulns } totalOpen={ ws.stats.opened_vulns } />
      <FieldContainer>
        <Label>Last Modified:</Label>
        <Text>
          { updateDate ? <TimeAgo date={ updateDate } /> : <div children="Not available." /> }
        </Text>
      </FieldContainer>
      <FieldContainer>
        <Label>Last Agent Execution:</Label>
        <Text>
          { lastRunAgent ? <TimeAgo date={ lastRunAgent } /> : <div children="Never." /> }
        </Text>
      </FieldContainer>
      <Histogram data={ ws.histogram } />
    </Wrapper>
  );
};

WorkspaceItem.propTypes = {
  ws: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.bool.isRequired
};

export default WorkspaceItem;

import fetchApi from 'services/api/connector';

export const addWorkspace = (data) => fetchApi('ws', { method: 'POST', data });

export const deleteWorkspace = (ws) => fetchApi(`ws/${ws}`, { method: 'DELETE' });

export const getReportStatus = (commandId) => fetchApi(`global_commands/${commandId}`);

export const getWsStats = (ws) => fetchApi(`ws/${ws}`);

export const createWorkspace = (data) => fetchApi('ws', { method: 'POST', data });

export const updateWorkspace = (ws, data) => fetchApi(`ws/${ws}`, { method: 'PATCH', data });

export const fetchWorkspaces = (queryParam) => fetchApi(`ws/filter?offset=0&limit=500&q=${escape(JSON.stringify(queryParam))}&histogram=true`);

export const getWsStatsFiltered = (ws, queryParam) => fetchApi(`ws/${ws}?${queryParam}`);

import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  selectUploadState, selectFileIndex, selectErrorFilesList,
  selectCurrentUploadState, selectCurrentUploadError
} from 'store/ManageEditCreate/selectors';
import ModalWrapper from 'Common/Components/ModalWrapper';
import { uploadFile, setFileIndex, setUploadState, resetFileUploadState } from 'Screens/ManageEditCreate/actions/Actions';
import { Button, ButtonsContainer, BackButton } from 'Common/Components/MainContainer/styled';
import { Wrapper, LeftTitle, BackBtn } from './styled';
import UploadSummary from './components/UploadSummary';
import DragStep from './components/DragStep';
import UploadStep from './components/UploadStep';
import ListStep from './components/ListStep';

const ImportFileCreationModal = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [files, setFiles] = useState([]);
  const [ignoreInfo, setIgnoreInfo] = useState(false);
  const [resolveHost, setResolveHost] = useState(false);
  const [step, setStep] = useState('DRAG');
  const uploadState = useSelector(selectUploadState);
  const fileIndex = useSelector(selectFileIndex);
  const errorFilesList = useSelector(selectErrorFilesList);
  const currentUploadState = useSelector(selectCurrentUploadState);
  const currentUploadError = useSelector(selectCurrentUploadError);

  const titleLabel = intl.formatMessage({ id: 'importFileCreationModal.title' });
  const backLabel = intl.formatMessage({ id: 'importFileCreationModal.backButton' });
  const doneLabel = intl.formatMessage({ id: 'importFileCreationModal.doneButton' });
  const uploadLabel = intl.formatMessage({ id: 'importFileCreationModal.uploadButton' });
  const cancelLabel = intl.formatMessage({ id: 'importFileCreationModal.cancelButton' });

  const onHandleClose = () => {
    setFiles([]);
    setStep('DRAG');
    dispatch(resetFileUploadState());
    handleClose();
  };

  const handleDrop = (filesUploading) => {
    const fileList = files;
    for (let i = 0; i < filesUploading.length; i += 1) {
      if (!filesUploading[i].name) return;
      if (fileList.findIndex((item) => (item.name === filesUploading[i].name && item.size === filesUploading[i].size)) === -1) {
        fileList.push(filesUploading[i]);
      }
    }
    setFiles([...fileList]);
    if (fileList.length > 0) setStep('LIST');
    dispatch(setFileIndex(fileList.length - 1));
    dispatch(setUploadState());
  };

  const stepComponent = {
    LIST: <ListStep
      files={ files }
      setFiles={ setFiles }
      ignoreInfo={ ignoreInfo }
      setIgnoreInfo={ setIgnoreInfo }
      resolveHost={ resolveHost }
      setResolveHost={ setResolveHost }
      setStep={ setStep }
      handleDrop={ handleDrop }
    />,
    DRAG: <DragStep
      files={ files }
      setFiles={ setFiles }
      ignoreInfo={ ignoreInfo }
      setIgnoreInfo={ setIgnoreInfo }
      resolveHost={ resolveHost }
      setResolveHost={ setResolveHost }
      setStep={ setStep }
    />,
    UPLOAD: <UploadStep
      files={ files }
      ignoreInfo={ ignoreInfo }
      resolveHost={ resolveHost }
    />,
    SUMMARY: <UploadSummary
      files={ files }
      errorFilesList={ errorFilesList }
    />
  };

  useEffect(() => {
    if (step === 'UPLOAD' && uploadState === 'PROCESSED' && fileIndex >= 0) dispatch(uploadFile([{ name: 'file', value: files[fileIndex] }]));
    if (step === 'UPLOAD' && uploadState === 'FINISHED' && fileIndex <= -1 && (currentUploadError === '' || files.length > 1)) setStep('SUMMARY');
    if (currentUploadState === 'Error' && step === 'DRAG') dispatch(resetFileUploadState());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, step, uploadState]);

  if (!show) return null;
  return (
    <ModalWrapper>
      <Wrapper>
        <LeftTitle children={ titleLabel } />
        { stepComponent[step] }
        <ButtonsContainer>
          { step === 'UPLOAD' && <BackButton onClick={ () => setStep('LIST') } children={ backLabel } /> }
          { step === 'SUMMARY'
            ? <Button onClick={ onHandleClose } children={ doneLabel } />
            : <Button onClick={ () => setStep('UPLOAD') } disabled={ step !== 'LIST' || isEmpty(files) } children={ uploadLabel } />
          }
          <BackBtn onClick={ onHandleClose } children={ cancelLabel } />
        </ButtonsContainer>
      </Wrapper>
    </ModalWrapper>
  );
};

ImportFileCreationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default ImportFileCreationModal;

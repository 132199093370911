import uniq from 'lodash/uniq';
import { copy } from '../../Common/Functions/Copy';
import * as types from './types';
import initialState from './initialState';
import { MANAGE_CREATE_UPDATE_SUCCESS } from 'Screens/ManageEditCreate/actions/Actions';

function vulns (state = initialState, action) {
  switch (action.type) {
    case types.SET_IMAGE_PREVIEW: {
      return {
        ...state,
        imagePreview: action.imagePreview
      };
    }

    case types.SEARCH_VULNS_START: {
      return {
        ...state,
        error: false,
        isFetching: true,
        vulnsSelected: []
      };
    }

    case types.SEARCH_VULNS_FAIL: {
      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false,
        error: true,
        errorMessage: action.data
      };
    }

    case types.SET_VULNS_FILTER_ERROR: {
      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false
      };
    }

    case types.VULN_SELECTED: {
      return {
        ...state,
        vulnsSelected: uniq([...state.vulnsSelected, ...action.payload])
      };
    }

    case types.NEW_PIVOT: {
      return {
        ...state,
        lastSelected: action.payload
      };
    }

    case types.VULN_UNSELECTED: {
      const filterByID = (array, id) => array.filter((el) => el._id !== id);
      const newVulnsSelected = action.payload.reduce((vulnsSelected, vulnToDelete) => filterByID(vulnsSelected, vulnToDelete._id), state.vulnsSelected);

      return {
        ...state,
        vulnsSelected: newVulnsSelected
      };
    }

    case types.SELECT_ALL_VULN: {
      return {
        ...state,
        vulnsSelected: [...action.vulnsList]
      };
    }

    case types.UNSELECT_ALL_VULN: {
      return {
        ...state,
        vulnsSelected: []
      };
    }

    case types.SHOW_MODAL_DELETE_CONFIRMATION_VULNS: {
      return {
        ...state,
        showDeleteConfirmation: true
      };
    }

    case types.HIDE_MODAL_DELETE_CONFIRMATION_VULNS: {
      return {
        ...state,
        showDeleteConfirmation: false,
        isFetching: false,
        isFetchingExpandedData: false
      };
    }

    case types.DELETE_VULNS: {
      return {
        ...state,
        isFetching: true,
        lastVulnSelected: initialState.lastVulnSelected,
        vulnsSelected: initialState.vulnsSelected,
        lastSelected: initialState.lastSelected,
        lastIndexForRange: initialState.lastIndexForRange
      };
    }

    case types.HIDE_MODAL_DELETE_CONFIRMATION_VULNS_SUCCEED: {
      return {
        ...state,
        showDeleteConfirmation: false
      };
    }

    case types.RESET_STATE_MANAGE: {
      return {
        ...initialState
      };
    }

    case types.CONFIRMATION_CHANGE_START: {
      return {
        ...state,
        isFetching: true,
        isFetchingExpandedData: true,
        error: false,
        errorMessage: ''
      };
    }

    case types.MANAGE_SET_BULK_UPDATE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false,
        error: false,
        errorMessage: ''
      };
    }

    case types.CONFIRMATION_CHANGE_SUCCESS: {
      const vulnsList = state.vulnsList;

      if (action.isGroupingBy) {
        const vulnGroup = vulnsList && vulnsList.find((g) => g.groupData && g.groupData.find((el) => el._id === action.vuln._id));
        const vulnGroupIndex = vulnsList && vulnsList.findIndex((g) => g.groupData && g.groupData.find((el) => el._id === action.vuln._id));
        const vulnSelected = vulnGroup.groupData && vulnGroup.groupData.findIndex((el) => el._id === action.vuln._id);
        vulnsList[vulnGroupIndex].groupData[vulnSelected] = copy(action.vuln);
      } else {
        const index = vulnsList.findIndex((x) => action.vuln._id === x._id);
        vulnsList[index] = copy(action.vuln);
      }

      const vulnDetail = state.vulnDetail ? copy(state.vulnDetail) : state.vulnDetail;

      if (state.vulnDetail && vulnDetail._id === action.vuln._id) vulnDetail.confirmed = action.vuln.confirmed;

      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false,
        vulnsList,
        vulnDetail
      };
    }

    case types.CONFIRMATION_CHANGE_FAIL: {
      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false,
        error: true,
        errorMessage: action.error
      };
    }

    case types.GET_GROUPED_DATA_START: {
      return {
        ...state,
        isFetchingExpandedData: true
      };
    }

    case types.GET_GROUPED_DATA_SUCCESS: {
      const vulnsList = state.vulnsList;
      vulnsList[action.index].groupData = action.data;

      return {
        ...state,
        vulnsList,
        isFetchingExpandedData: false
      };
    }

    case types.RESET_ERROR_VALUE: {
      return {
        ...state,
        error: false,
        errorMessage: ''
      };
    }

    case types.SHOW_MANAGE_LEFT_FILTERS: {
      return {
        ...state,
        showLeftFilters: action.visible,
        showVulnDetail: action.visible && action.breakpoint !== 'fourth' ? false : state.showVulnDetail
      };
    }

    case types.SHOW_VULNERABILITY_DETAIL: {
      return {
        ...state,
        vulnDetail: action.vuln,
        vulnDetailSelectedTab: action.vulnDetailSelectedTab || state.vulnDetailSelectedTab,
        showVulnDetail: true
      };
    }

    case types.HIDE_VULNERABILITY_DETAIL: {
      return {
        ...state,
        vulnDetail: undefined,
        showVulnDetail: false,
        vulnDetailSelectedTab: initialState.vulnDetailSelectedTab
      };
    }

    case types.SET_VULNERABILITY_DETAIL_TAB: {
      return {
        ...state,
        vulnDetailSelectedTab: action.vulnDetailSelectedTab
      };
    }

    case types.UPDATE_VULN_MANAGE_PREVIEW_SUCCESS: {
      const vulnsSelectedArray = state.vulnsSelected;
      const vulnUpdatedId = vulnsSelectedArray.findIndex((vuln) => vuln._id === action.vuln._id);
      vulnsSelectedArray.splice(vulnUpdatedId, 1, action.vuln);
      return {
        ...state,
        isFetchingPreview: false,
        vuln: action.vuln,
        vulnDetail: { ...action.vuln },
        vulnsList: action.vulnsList,
        vulnsSelected: vulnsSelectedArray
      };
    }

    case types.UPDATE_VULN_MANAGE_PREVIEW_FAILURE: {
      return {
        ...state
      };
    }

    case types.SELECT_GROUP_MANAGE: {
      let vulnsSelected = state.vulnsSelected;

      if (action.selected) {
        state.vulnsList[action.index].groupData.forEach((vuln) => {
          vulnsSelected.push(vuln);
        });
      } else {
        const groupVulns = state.vulnsList[action.index].groupData.map((x) => x._id);
        vulnsSelected = vulnsSelected.filter((x) => !groupVulns.includes(x._id));
      }

      return {
        ...state,
        vulnsSelected
      };
    }

    case types.SELECT_VULN_GROUP_MANAGE: {
      let vulnsSelected = state.vulnsSelected;

      if (action.selected) vulnsSelected.push(action.vuln);
      else vulnsSelected = vulnsSelected.filter((vuln) => vuln._id !== action.vuln._id);

      return {
        ...state,
        vulnsSelected
      };
    }

    case types.MANAGE_SET_BULK_UPDATE_FIELD: {
      return {
        ...state,
        bulkUpdateField: action.field,
        bulkUpdateValue: ''
      };
    }

    case types.MANAGE_SET_BULK_UPDATE_VALUE: {
      return {
        ...state,
        bulkUpdateValue: action.value
      };
    }

    case types.MANAGE_ADD_BULK_UPDATE_VALUE: {
      const bulkUpdateValue = state.bulkUpdateValue;
      bulkUpdateValue.push(action.value);

      return {
        ...state,
        bulkUpdateValue
      };
    }

    case types.MANAGE_REMOVE_BULK_UPDATE_VALUE: {
      let bulkUpdateValue = state.bulkUpdateValue;
      bulkUpdateValue = bulkUpdateValue.filter((element) => element !== action.value);

      return {
        ...state,
        bulkUpdateValue
      };
    }

    case types.MANAGE_BULK_UPDATE_FINISHED: {
      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false,
        bulkUpdateField: '',
        bulkUpdateValue: ''
      };
    }

    case types.MANAGE_BULK_SAVE_TEMPLATE_FINISHED: {
      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false
      };
    }

    case types.GET_TEMPLATES_BULK_START: {
      return {
        ...state,
        isFetching: true
      };
    }

    case types.GET_TEMPLATES_BULK_FAIL: {
      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false,
        error: true,
        errorMessage: 'Failed to get templates'
      };
    }

    case types.GET_TEMPLATES_BULK_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false,
        templates: action.data
      };
    }

    case types.GET_VULNS_SUCCESS_MANAGE: {
      return {
        ...state,
        error: false,
        vulnsList: action.data,
        vulnsCount: action.vulnsCount,
        isFetching: false,
        isFetchingExpandedData: false
      };
    }

    case types.REFRESH_VULNS: {
      return {
        ...state,
        vulnsList: action.vulnsList,
        vulnsCount: action.vulnsCount,
        vulnsSelected: action.vulnsSelected,
        vulnDetail: action.vulnDetail
      };
    }

    case types.UPDATE_VULNS_LIST: {
      return {
        ...state,
        vulnsList: action.vulnsList
      };
    }

    case types.ADD_MOCK_VULN: {
      return {
        ...state,
        vulnsList: action.data,
        vulnsCount: action.vulnsCount,
        isFetching: false,
        isFetchingExpandedData: false,
        usesMockVulns: true
      };
    }

    case types.REMOVE_MOCK_VULN: {
      return {
        ...state,
        vulnsList: action.vulnsList,
        vulnsCount: action.vulnsCount,
        usesMockVulns: false,
        vulnsSelected: initialState.vulnsSelected
      };
    }

    case types.MANAGE_VULN_ERROR: {
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage
      };
    }

    case types.READ_ONLY_ERROR: {
      return {
        ...state,
        error: true,
        errorMessage: action.error
      };
    }
    case types.SHOW_CONTEXT_MENU: {
      return {
        ...state,
        showContextMenu: action.show,
        contextMenuXPos: action.contextMenuXPos,
        contextMenuYPos: action.contextMenuYPos
      };
    }
    case types.SAVE_TEMPLATE_MANAGE: {
      return {
        ...state,
        templatesCreated: action.templatesCreated,
        failedTemplates: action.errors
      };
    }
    case types.CLOSE_TEMPLATE_MODAL_MESSAGE: {
      return {
        ...state,
        templatesCreated: [],
        failedTemplates: []
      };
    }
    case MANAGE_CREATE_UPDATE_SUCCESS: {
      const vulnsList = state.vulnsList;

      return {
        ...state,
        vulnsList: [action.vuln, ...vulnsList]
      };
    }

    case types.SELECT_TOTAL_VULNS: {
      return {
        ...state,
        selectAll: true
      };
    }

    case types.UNSELECT_TOTAL_VULNS: {
      return {
        ...state,
        selectAll: false
      };
    }

    default: {
      return state;
    }
  }
}

export default vulns;

import React, { useState, useRef } from 'react';
import get from 'lodash/get';
import Header from './components/Header';
import Tabs from './components/Tabs';
import { Wrapper, DetailWrapper, ResizerWrapper, Resizer } from './styled';
import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_UPGRADE_LICENSE_VULN_DETAIL } from 'store/modals/modals';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import FFTags from 'Images/ff_tags.png';
import { useResizeX } from 'Hooks/useResize';
import useWindowDimensions from 'Hooks/useWindowSize';
import ImagePreviewModal from './components/ImagePreviewModal';

const VulnerabilityDetail = () => {
  const [showTransparency, setShowTransparency] = useState(false);
  const ref = useRef(null);
  const wrapperRef = useRef(null);
  const resizerRef = useRef(null);
  const { width } = useWindowDimensions();
  const wrapperWidth = useResizeX(wrapperRef, resizerRef, width * 0.55, width * 0.55, width * 0.75);

  const onScroll = () => {
    const scrollTop = get(ref, 'current.scrollTop', 0);
    if (scrollTop === 0) setShowTransparency(false);
    else setShowTransparency(true);
  };

  return (
    <>
      <ReduxModal id={ MODAL_UPGRADE_LICENSE_VULN_DETAIL }>
        <UpgradeLicense id={ MODAL_UPGRADE_LICENSE_VULN_DETAIL } image={ FFTags } descriptionIntlId={ 'upgradeLicense.sidebar.tags.description' } />
      </ReduxModal>
      <ImagePreviewModal />
      <Wrapper ref={ wrapperRef } wrapperWidth={ wrapperWidth }>
        <ResizerWrapper ref={ resizerRef } children={ <Resizer /> } />
        <DetailWrapper>
          <Header showTransparency={ showTransparency } />
          <Tabs tabRef={ ref } onScroll={ onScroll } />
        </DetailWrapper>
      </Wrapper>
    </>
  );
};

export default VulnerabilityDetail;

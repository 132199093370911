/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectRedirect, selectShowGridView } from 'store/Workspace/selectors';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { stopRedirect, getData } from 'Screens/Workspaces/actions/Actions';
import { setSelectedEntity, resetFilters } from 'store/Filters/actions';
import Table from './components/Table';
import WSActionBar from './components/WSActionBar';
import Wrapper from './styled';
import GridView from './components/GridView';

const Workspace = () => {
  const dispatch = useDispatch();
  const currentWorkspace = useSelector(selectCurrentWorkspace);
  const redirect = useSelector(selectRedirect);
  const showGridView = useSelector(selectShowGridView);

  useEffect(() => {
    dispatch(getData());
  }, [dispatch]);

  useEffect(() => () => {
    dispatch(resetFilters('workspaces'));
    dispatch(setSelectedEntity(''));
  }, [dispatch]);

  if (redirect) {
    dispatch(stopRedirect());
    return <Redirect to={ `/manage/${currentWorkspace}` } />;
  }

  return (
    <Wrapper>
      <WSActionBar />
      { showGridView ? <GridView /> : <Table /> }
    </Wrapper>
  );
};

export default Workspace;

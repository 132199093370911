/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import CustomReactTable from 'Common/Components/CustomReactTable/styled';
import handleLeftClick from 'Common/Components/CustomReactTable/leftClickHelper';
import PropTypes from 'prop-types';
import {
  ContextMenuTrigger
} from 'react-contextmenu';
import ContextMenuWorkspaceMassiveUpdate from 'Screens/Workspaces/components/ContextMenuWorkspaceMassiveUpdate';
import {
  enableDisable,
  changeReadOnly,
  selectWorkspace,
  setOrderByWorkspaces
} from 'Screens/Workspaces/actions/Actions';
import { connect } from 'react-redux';
import {
  selectIsFetching, selectLastIndexForRange, selectLastSelected, selectLastWorkspaceSelected, selectShowInactive, selectWorkspacesOrdered, selectWorkspacesSelected
} from 'store/Workspace/selectors';
import { TableWrapper } from './styled';
import getColumns from './Columns';
import { FormattedMessage } from 'react-intl';

const getSorting = (sorted) => {
  const vulnsSortIndex = sorted.findIndex((s) => s.id === 'vulnerabilities');

  if (vulnsSortIndex >= 0) {
    const desc = sorted[vulnsSortIndex].desc;
    const sortCriticalCount = { id: 'vulnerability_critical_count', desc };

    const newSorting = [...sorted];
    newSorting.splice(vulnsSortIndex, 1, sortCriticalCount);
    return newSorting;
  }
  return sorted;
};

class Table extends Component {
  constructor (props) {
    super(props);
    this.table = React.createRef();
    this.tableBody = React.createRef();
    this.fetchData = this.fetchData.bind(this);
    this.getTrProps = this.getTrProps.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount () {
    const tbody = this.table;

    if (tbody) {
      tbody.addEventListener('scroll', this.handleScroll, true);
      this.tableBody = document.getElementsByClassName('ReactTable')[0];
    }
  }

  handleClick (e, rowInfo) {
    const selected = this.props.workspacesSelected.filter((x) => x.id === rowInfo.original.id).length > 0;

    const {
      workspaceList, workspacesSelected, lastWorkspaceSelected, lastIndexForRange, lastSelected
    } = this.props;
    const leftClickResponse = handleLeftClick(e, rowInfo, '', selected, workspaceList, workspacesSelected, lastWorkspaceSelected, lastIndexForRange, lastSelected);
    if (leftClickResponse) this.props.selectWorkspace(leftClickResponse);
  }

  getTrProps (state, rowInfo) {
    const selected = this.props.workspacesSelected.filter((x) => x.id === rowInfo.original.id).length > 0;

    return {
      onClick: (e) => {
        this.handleClick(e, rowInfo);
        return true;
      },
      onContextMenu: () => {
        if (!selected) this.props.selectWorkspace({ newRowsSelected: [rowInfo.original] });
        return false;
      },
      style: {
        background: selected ? '#edf2f7' : 'transparent'
      }
    };
  }

  fetchData (state) {
    const { isFetching } = this.props;
    const { sorted } = state;
    if (!isFetching) {
      const sorting = getSorting(sorted);
      this.props.setOrderBy(sorting);
    }
  }

  render () {
    const {
      workspaceList,
      isFetching,
      showInactive,
      permissions
    } = this.props;

    const mockData = [...Array(13).keys()];

    return (
      <TableWrapper ref={ (element) => { this.table = element; } }>
        <ContextMenuTrigger id="massive_update_workspace_table" holdToDisplay={ 1000000 }>
          <CustomReactTable
            // eslint-disable-next-line no-nested-ternary
            data={ isFetching ? mockData : showInactive ? workspaceList : workspaceList.filter((ws) => ws.active) }
            columns={ getColumns(this.props.enableDisable, this.props.changeReadOnly, this.handleClick, isFetching) }
            minRows={ 0 }
            style={ { height: 'calc(100vh - 158px)', boxShadow: '-8px 14px 20px 0px rgba(0, 0, 0, 0.03)' } }
            onFetchData={ this.fetchData }
            manual
            getTrProps={ this.getTrProps }
            showPagination={ false }
            noDataText={ <FormattedMessage id="app.nodata" /> }
          />
        </ContextMenuTrigger>
        { permissions.update.allowed
          ? <ContextMenuWorkspaceMassiveUpdate trigger="massive_update_workspace_table" onShow={ () => { } } />
          : null}
      </TableWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  workspacesSelected: selectWorkspacesSelected(state),
  lastWorkspaceSelected: selectLastWorkspaceSelected(state),
  lastIndexForRange: selectLastIndexForRange(state),
  lastSelected: selectLastSelected(state),
  workspaceList: selectWorkspacesOrdered(state),
  isFetching: selectIsFetching(state),
  showInactive: selectShowInactive(state),
  permissions: state.faraday.permissions.workspaces
});

const mapDispatchToProps = (dispatch) => ({
  enableDisable: (ws, value) => {
    dispatch(enableDisable(ws, value));
  },
  changeReadOnly: (ws, readonly) => {
    dispatch(changeReadOnly(ws, readonly));
  },
  selectWorkspace: (index, workspace, selected, reset, shiftPressed) => {
    dispatch(selectWorkspace(index, workspace, selected, reset, shiftPressed));
  },
  setOrderBy: (sorting) => {
    dispatch(setOrderByWorkspaces(sorting));
  }
});

Table.propTypes = {
  enableDisable: PropTypes.func.isRequired,
  changeReadOnly: PropTypes.func.isRequired,
  selectWorkspace: PropTypes.func.isRequired,
  workspacesSelected: PropTypes.arrayOf(PropTypes.object).isRequired,
  lastWorkspaceSelected: PropTypes.instanceOf(Object).isRequired,
  lastIndexForRange: PropTypes.number.isRequired,
  lastSelected: PropTypes.number.isRequired,
  workspaceList: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetching: PropTypes.bool.isRequired,
  showInactive: PropTypes.bool.isRequired,
  permissions: PropTypes.instanceOf(Object).isRequired
};
export default connect(mapStateToProps, mapDispatchToProps)(Table);

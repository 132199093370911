import * as types from 'store/Manage/types';
import * as FileSaver from 'file-saver';
import api from 'services/api';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import set from 'lodash/set';
import { MOCK_VULN_ID } from 'Common/Components/Tour/helpers/mockVuln';
import setTourFinished from 'store/Preferences/Tour/actions';
import {
  selectVulnDetailId,
  selectVulnsList,
  selectVulnDetail,
  selectUsesMockVulns,
  selectVulnsCount,
  selectLastSelected,
  selectVulnsSelected,
  selectShowDetail,
  selectModalBulkUpdateField,
  selectModalBulkUpdateValue,
  selectContextMenuXPos,
  selectContextMenuYPos,
  selectSelectAll
} from 'store/Manage/selectors';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { getData } from 'Screens/Workspaces/actions/Actions';
import {
  selectGroupByField, selectIsGrouping, selectRowsPerPage,
  selectQueryParam, selectAdvancedFilterQueryParam
} from 'store/Filters/selectors';
import { redirect } from 'store/Router/actions';
import { selectCsrfToken } from 'store/Sesion/selectors';
import { MODAL_MANAGE_BULK_UPDATE, MODAL_MANAGE_BULK_UPDATE_CONFIRMATION } from 'store/modals/modals';
import { closeModal, openModal } from 'store/modals/actions';
import { setFilterError } from 'store/Filters/actions';
import { newGetVulns, setVulnsFilterError } from './filterActions';

export function setImagePreview (imagePreview) {
  return (dispatch, getState) => {
    dispatch({
      type: types.SET_IMAGE_PREVIEW, imagePreview
    });
  };
}

const addDeleteController = (vulns, vulnList) => { // if all vulns are in vulnList then unselect then if not
  const allVulnsAreSelected = vulns.every((testVuln) => vulnList.some((vuln) => vuln._id === testVuln._id));

  if (allVulnsAreSelected) return [types.VULN_UNSELECTED, vulns];
  return [types.VULN_SELECTED, vulns];
};

const selectCalculator = (e, vuln, vulnList, areVulnsSelected) => (dispatch, getState) => {
  const pivot = selectLastSelected(getState());
  const index = vulnList.findIndex((el) => el._id === vuln._id);
  const vulnsSelected = selectVulnsSelected(getState());

  dispatch({ type: types.NEW_PIVOT, payload: index });
  if (e.shiftKey && pivot !== -1 && areVulnsSelected) { // if already had vuln selected and shift key is pressed
    const start = Math.min(pivot, index);
    const end = Math.max(pivot, index) + 1;
    const vulns = vulnList.slice(start, end);
    const [type, payload] = addDeleteController(vulns, vulnsSelected);
    return dispatch({ type, payload });
  }
  const [type, payload] = addDeleteController([vuln], vulnsSelected);
  return dispatch({ type, payload });
};

export const selectRow = (e, vuln) => (dispatch, getState) => {
  const allVulns = selectVulnsList(getState());
  const isGroupingBy = selectIsGrouping('vulns', getState());
  const vulnsSelected = selectVulnsSelected(getState());

  if (isGroupingBy) {
    const group = allVulns.find((g) => g.groupData && g.groupData.find((el) => el._id === vuln._id));
    if (!group) return; // si no hay groupData no se puede hacer calculos con las vulns
    const vulnGroup = group.groupData;
    const areVulnsSelected = vulnsSelected.length > 0 && vulnGroup.some((el) => el.id === vuln.id);
    dispatch(selectCalculator(e, vuln, vulnGroup, areVulnsSelected, vulnsSelected));
  } else {
    dispatch(selectCalculator(e, vuln, allVulns, vulnsSelected.length > 0));
  }
};

export const unSelectAll = () => (dispatch) => dispatch({ type: types.UNSELECT_ALL_VULN });

export const selectAllVulns = () => (dispatch, getState) => {
  const vulnsList = selectVulnsList(getState());
  const vulnsSelected = selectVulnsSelected(getState());
  const vulnCount = selectVulnsCount(getState());
  const pageSize = selectRowsPerPage('vulns', getState());

  if (vulnsSelected.length === vulnCount || vulnsSelected.length >= pageSize) return dispatch(unSelectAll());

  return dispatch({ type: types.SELECT_ALL_VULN, vulnsList });
};

export const selectTotalVulns = () => (dispatch, getState) => { // includes server vulns
  return dispatch({ type: types.SELECT_TOTAL_VULNS });
};

export const unselectTotalVulns = () => (dispatch, getState) => { // includes server vulns
  return dispatch({ type: types.UNSELECT_TOTAL_VULNS });
};

export const selectConfirmedVulns = () => (dispatch, getState) => {
  const state = getState();
  const vulnsList = selectVulnsList(state).filter((vuln) => (vuln.confirmed));

  return dispatch({ type: types.SELECT_ALL_VULN, vulnsList });
};

export const selectNotConfirmedVulns = () => (dispatch, getState) => {
  const state = getState();
  const vulnsList = selectVulnsList(state).filter((vuln) => (!vuln.confirmed));
  return dispatch({ type: types.SELECT_ALL_VULN, vulnsList });
};

// Summary: Show confirmation modal when user delete vuln/s
export function showVulnModalDelete () {
  return (dispatch) => {
    dispatch({ type: types.SHOW_MODAL_DELETE_CONFIRMATION_VULNS });
  };
}

// Summary: Hide confirmation modal when user delete vuln/s
export function hideVulnModalDelete () {
  return (dispatch) => {
    dispatch({ type: types.HIDE_MODAL_DELETE_CONFIRMATION_VULNS });
  };
}

// Summary: reset state of manage screen
export function resetState () {
  return (dispatch) => {
    dispatch({ type: types.RESET_STATE_MANAGE });
  };
}

function exportCsv (result) {
  return async (_, getState) => {
    const workspaceSelected = selectCurrentWorkspace(getState());
    let title = '';

    if (workspaceSelected) title = `SR-${workspaceSelected}`;
    else title = 'Vulnerability Model CSV';

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.csv';

    const data = new Blob([result], { type: fileType });
    FileSaver.saveAs(data, title + fileExtension);
  };
}

export function resetErrorValue () {
  return (dispatch) => {
    dispatch({ type: types.RESET_ERROR_VALUE });
  };
}

export function showManageLeftFilters (visible, breakpoint) {
  return (dispatch) => {
    dispatch({ type: types.SHOW_MANAGE_LEFT_FILTERS, visible, breakpoint });
  };
}

export function redirectToVulnDetail (id) {
  return (dispatch, getState) => {
    const currentWorkspace = selectCurrentWorkspace(getState());
    dispatch(redirect(`/manage/${currentWorkspace}/${id}`));
  };
}

export function redirectToManage () {
  return (dispatch, getState) => {
    const currentWorkspace = selectCurrentWorkspace(getState());
    dispatch(redirect(`/manage/${currentWorkspace}`));
  };
}

export function setVulnerabilityDetailTab (vulnDetailSelectedTab) {
  return (dispatch) => {
    dispatch({ type: types.SET_VULNERABILITY_DETAIL_TAB, vulnDetailSelectedTab });
  };
}

export function showVulnerabilityDetail (id, vulnDetailSelectedTab) {
  return (dispatch, getState) => {
    const currentWorkspace = selectCurrentWorkspace(getState());
    if (id > 0) {
      dispatch(redirect(`/manage/${currentWorkspace}/${id}`));
      // Redirecting to manage/id calls loadVulnerabilityDetail, fetching the detail and showing it.
      dispatch(setVulnerabilityDetailTab(vulnDetailSelectedTab));
    }
  };
}

export function loadVulnerabilityDetail (id) {
  return async (dispatch, getState) => {
    if (id > 0) {
      const currentWorkspace = selectCurrentWorkspace(getState());
      try {
        const vuln = await api.manage.fetchById(currentWorkspace, id);
        dispatch({ type: types.SHOW_VULNERABILITY_DETAIL, vuln });
      } catch (e) {
        dispatch(redirectToManage());
      }
    }
  };
}

export function hideVulnerabilityDetail () {
  return (dispatch, getState) => {
    const showVulnDetail = selectShowDetail(getState());
    if (showVulnDetail) dispatch({ type: types.HIDE_VULNERABILITY_DETAIL });
  };
}

export function addEvidences (evidences, getEvidences) {
  return async (dispatch, getState) => {
    const id = selectVulnDetailId(getState());
    const csrfToken = selectCsrfToken(getState());
    const workspaceSelected = selectCurrentWorkspace(getState());

    const promises = evidences.map((evidence) => {
      const newEvidenceName = evidence.name.replace(/\s+/g, '');
      const renameEvidence = new File([evidence], newEvidenceName);
      const fd = new FormData();
      fd.append('csrf_token', csrfToken);
      fd.append('file', renameEvidence);
      return api.attachments.saveAttachments(workspaceSelected, id, fd);
    });

    try {
      await Promise.all(promises);
    } catch (e) {
      // error
    }
    getEvidences();
  };
}

function vulnPreviewEditSuccedCallback (vulnBefore, vulnAfter) {
  return (dispatch, getState) => {
    const groupBy = selectGroupByField('vulns', getState());
    const vulnsList = selectVulnsList(getState());

    if (groupBy) {
      // se actualiza la tabla agrupada
      const indexGroupDataAfter = vulnsList.findIndex((x) => x[groupBy] === vulnAfter[groupBy]);
      const groupDataAfter = vulnsList[indexGroupDataAfter].groupData || [];
      const indexVulnAfter = groupDataAfter.findIndex((x) => vulnAfter._id === x.id || vulnAfter._id === x._id);
      if (indexVulnAfter < 0) { // no lo encontro, lo borro
        const indexGroupDataBefore = vulnsList.findIndex((x) => x[groupBy] === vulnBefore[groupBy]);
        const groupDataBefore = vulnsList[indexGroupDataBefore].groupData || {};
        const indexVulnBefore = groupDataBefore.findIndex((x) => vulnAfter._id === x.id || vulnAfter._id === x._id);
        // lo quito del grupo.
        vulnsList[indexGroupDataBefore].groupData.splice(indexVulnBefore, 1);
        // eslint-disable-next-line no-negated-condition
        if (!vulnsList[indexGroupDataAfter].groupData) vulnsList[indexGroupDataAfter].count += 1;
        else vulnsList[indexGroupDataAfter].groupData.push(vulnAfter);
      } else {
        vulnsList[indexGroupDataAfter].groupData[indexVulnAfter] = vulnAfter;
      }
    } else {
      // se actualiza la tabla
      const index = vulnsList.findIndex((x) => vulnAfter._id === x.id || vulnAfter._id === x._id);
      vulnsList[index] = vulnAfter;
    }

    dispatch({ type: types.UPDATE_VULN_MANAGE_PREVIEW_SUCCESS, vuln: vulnAfter, vulnsList });
  };
}

function vulnPreviewEditErrorCallback () {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_VULN_MANAGE_PREVIEW_FAILURE, error: 'An error has occurred' });
  };
}

export function updateVuln (vulnBefore, field, value) {
  return async (dispatch, getState) => {
    const workspaceSelected = selectCurrentWorkspace(getState());
    let vulnAfter = { ...vulnBefore };
    set(vulnAfter, field, value);

    try {
      let newRefs = [];
      if (field === 'refs') {
        newRefs = value.map((ref) => ({ name: ref, type: 'other' }));
        vulnAfter = await api.manage.updateVuln(workspaceSelected, { _id: vulnBefore._id, refs: newRefs });
      } else {
        vulnAfter = await api.manage.updateVuln(workspaceSelected, { _id: vulnBefore._id, [field]: value });
      }

      dispatch(vulnPreviewEditSuccedCallback(vulnBefore, vulnAfter));
    } catch (error) {
      dispatch(vulnPreviewEditErrorCallback());
    }
  };
}

export function selectGroup (index, selected) {
  return (dispatch) => {
    dispatch({ type: types.SELECT_GROUP_MANAGE, index, selected });
  };
}

export function selectVulnGroup (vuln, selected) {
  return (dispatch) => {
    dispatch({ type: types.SELECT_VULN_GROUP_MANAGE, vuln, selected });
  };
}

export function setBulkUpdateField (field) {
  return (dispatch) => {
    dispatch({ type: types.MANAGE_SET_BULK_UPDATE_FIELD, field });
  };
}

export function setBulkUpdateValue (value) {
  return (dispatch) => {
    dispatch({ type: types.MANAGE_SET_BULK_UPDATE_VALUE, value });
  };
}

export function addBulkUpdateValue (value) {
  return (dispatch) => {
    dispatch({ type: types.MANAGE_ADD_BULK_UPDATE_VALUE, value });
  };
}

export function removeBulkUpdateValue (value) {
  return (dispatch) => {
    dispatch({ type: types.MANAGE_REMOVE_BULK_UPDATE_VALUE, value });
  };
}

export function refreshVulnsList (vulnsList, vulnsSelected, vulnDetail) {
  return (dispatch, getState) => {
    dispatch({
      type: types.REFRESH_VULNS, vulnsList, vulnsSelected, vulnDetail, vulnsCount: selectVulnsCount(getState())
    });
  };
}

const getImpactData = (value, selectedVulns) => {
  const keyNames = Object.keys(selectedVulns[0].impact);
  let impact = {};
  keyNames.forEach((imp) => {
    impact = {
      ...impact,
      [imp]: value.some((x) => x === imp)
    };
  });
  return { impact };
};

export function bulkUpdateVulns () {
  return async (dispatch, getState) => {
    const state = getState();

    dispatch({ type: types.CONFIRMATION_CHANGE_START });

    try {
      const vulnsList = selectVulnsList(state);
      const vulnsSelected = selectVulnsSelected(state);
      const vulnDetail = selectVulnDetail(state);
      const field = selectModalBulkUpdateField(state);
      const value = selectModalBulkUpdateValue(state);
      const workspaceSelected = selectCurrentWorkspace(state);
      const selectAll = selectSelectAll(state);
      const vulnIDs = vulnsSelected.map((v) => v._id);

      const advancedFilterQueryParam = selectAdvancedFilterQueryParam(state, 'vulns');
      const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
      const standardQueryParam = selectQueryParam('vulns', state);
      const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

      let data = {};

      if (field === 'references') data = { refs: value.map((v) => ({ name: v, type: 'other' })) };
      else if (field === 'policy violations') data = { policyviolations: value };
      else if (field === 'description') data = { description: value, desc: value };
      else if (field === 'impact') data = getImpactData(value, vulnsSelected);
      else data = { [field]: value };

      const isDetailVulnSelected = vulnsSelected.some((selectedVuln) => !isEmpty(vulnDetail) && selectedVuln._id === vulnDetail._id);

      const updatedVulnDetail = isDetailVulnSelected ? { ...vulnDetail, ...data } : { ...vulnDetail };

      if (selectAll) {
        await api.manage.updateAllVulns(workspaceSelected, { filters: queryParam.filters }, data);

        const updatedVulnsList = vulnsList.map((vuln) => ({ ...vuln, ...data }));
        const updatedSelectedVulns = vulnsSelected.map((vuln) => ({ ...vuln, ...data }));

        dispatch(refreshVulnsList(updatedVulnsList, updatedSelectedVulns, updatedVulnDetail));
      } else {
        const payload = { ids: vulnIDs, ...data };

        await api.manage.updateVulns(workspaceSelected, payload);

        const updatedVulnsList = vulnsList.map((vuln) => {
          const isSelected = vulnsSelected.some((selectedVuln) => selectedVuln._id === vuln._id);
          if (isSelected) return { ...vuln, ...data };
          return vuln;
        });

        const updatedSelectedVulns = vulnsSelected.map((vuln) => ({ ...vuln, ...data }));

        dispatch({ type: types.MANAGE_SET_BULK_UPDATE_SUCCESS });
        dispatch(refreshVulnsList(updatedVulnsList, updatedSelectedVulns, updatedVulnDetail));
      }

      dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE));
      dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
      dispatch({ type: types.MANAGE_BULK_UPDATE_FINISHED });
    } catch (e) {
      dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE));
      dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
      dispatch({ type: types.MANAGE_VULN_ERROR, errorMessage: e.message || 'An error occured while updating vulns' });
    }
  };
}

export function saveTemplateFromVuln () {
  return (dispatch, getState) => {
    const vulnsSelected = selectVulnsSelected(getState());

    const save = async (template) => {
      const response = await api.knowledgeBase.createTemplate(template);
      return response;
    };

    const data = vulnsSelected.map((vuln) => {
      const template = {
        cwe: '',
        description: vuln.desc,
        desc: vuln.desc,
        data: vuln.data,
        exploitation: vuln.severity,
        name: vuln.name,
        references: vuln.refs.map(ref => ref.name),
        refs: vuln.refs.map(ref => ref.name),
        resolution: vuln.resolution,
        impact: vuln.impact,
        policyviolations: vuln.policyviolations,
        customfields: vuln.custom_fields,
        easeofresolution: vuln.easeofresolution,
        external_id: vuln.external_id,
        type: 'vulnerability_template'
      };
      return save(template);
    });

    Promise.allSettled(data).then((results) => {
      const templatesCreated = results ? results.filter((template) => template.status === 'fulfilled').map((t) => t.value) : [];
      const errors = results ? results.filter((template) => template.status === 'rejected').map((t) => t.reason.object) : [];
      dispatch({ type: types.SAVE_TEMPLATE_MANAGE, templatesCreated, errors });
    });
  };
}

export function getTemplatesMassiveUpdate () {
  return async (dispatch) => {
    dispatch({ type: types.GET_TEMPLATES_BULK_START });

    try {
      const data = await api.manage.getTemplates();
      dispatch({ type: types.GET_TEMPLATES_BULK_SUCCESS, data });
    } catch (e) {
      dispatch({ type: types.GET_TEMPLATES_BULK_FAIL });
    }
  };
}

export function showBulkUpdateModal (bulkUpdateField, bulkUpdateValue) {
  return (dispatch) => {
    dispatch(setBulkUpdateField(bulkUpdateField));
    dispatch(setBulkUpdateValue(bulkUpdateValue));
    dispatch(openModal(MODAL_MANAGE_BULK_UPDATE));
  };
}

export function onGetVulnsFail (errorMessage) {
  return (dispatch) => {
    dispatch({ type: types.SEARCH_VULNS_FAIL, data: errorMessage });
  };
}

// Summary: Delete vuln/s database
export function deleteSelectedVulns () {
  return async (dispatch, getState) => {
    const workspaceSelected = selectCurrentWorkspace(getState());
    const vulnsSelected = selectVulnsSelected(getState());
    const vulnIDs = vulnsSelected.map((vuln) => vuln._id);
    const groupByField = selectGroupByField('vulns', getState());
    const vulnsList = selectVulnsList(getState());
    const selectAll = selectSelectAll(getState());

    const advancedFilterQueryParam = selectAdvancedFilterQueryParam(getState(), 'vulns');
    const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
    const standardQueryParam = selectQueryParam('vulns', getState());
    const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

    dispatch({ type: types.HIDE_MODAL_DELETE_CONFIRMATION_VULNS_SUCCEED });
    dispatch({ type: types.DELETE_VULNS });

    try {
      if (selectAll) {
        await api.manage.deleteAllVulns(workspaceSelected, { filters: queryParam.filters });
      } else {
        await api.manage.deleteVulns(workspaceSelected, vulnIDs);
      }
      dispatch(getData());
      if (groupByField) {
        const newVulnsList = vulnsList.map((group) => {
          const groupedData = get(group, 'groupData', null);
          if (groupedData) {
            const newGroupedData = groupedData.filter((vuln) => !vulnIDs.includes(vuln._id));
            return {
              ...group,
              count: newGroupedData.length,
              groupData: newGroupedData
            };
          }
          return group;
        });
        dispatch({ type: types.UPDATE_VULNS_LIST, vulnsList: newVulnsList });
      } else dispatch(newGetVulns());
    } catch (e) {
      dispatch({ type: types.MANAGE_VULN_ERROR, errorMessage: e.message || 'An error occured while deleting vulns' });
    }
  };
}

export function removeMockVuln () {
  return (dispatch, getState) => {
    const vulnList = selectVulnsList(getState());
    const newVulnsList = vulnList.filter((vuln) => vuln._id !== MOCK_VULN_ID);
    const vulnsCount = newVulnsList.length > 0 ? newVulnsList.length - 1 : 0;
    dispatch({ type: types.REMOVE_MOCK_VULN, vulnsList: newVulnsList, vulnsCount });
  };
}

export function endTour () {
  return async (dispatch, getState) => {
    const usesMockVulns = selectUsesMockVulns(getState());
    if (usesMockVulns) dispatch(removeMockVuln());
    dispatch(setTourFinished(true));
  };
}

export function downloadVulns () {
  return async (dispatch, getState) => {
    const workspace = selectCurrentWorkspace(getState());

    const state = getState();
    let advancedFilterQueryParam = [];
    try {
      advancedFilterQueryParam = selectAdvancedFilterQueryParam(state, 'vulns', true);
    } catch (e) {
      dispatch(setFilterError('vulns', 'Syntax error. Please try again. For further help check our documentation'));
      return dispatch(setVulnsFilterError());
    }

    const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
    const standardQueryParam = selectQueryParam('vulns', state, true);
    const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

    try {
      const response = await api.manage.exportCSV(workspace, queryParam);
      dispatch(exportCsv(response));
    } catch (err) {
      dispatch({ type: types.EXPORT_CSV_MANAGE_FAILURE });
    }

    return dispatch({ type: types.EXPORT_CSV_MANAGE_SUCCESS });
  };
}

export function showContextMenu (show, XPos, YPos) {
  return (dispatch, getState) => {
    const state = getState();
    const currentXPos = selectContextMenuXPos(state);
    const currentYPos = selectContextMenuYPos(state);
    const newXPos = XPos ? (XPos + 1) : currentXPos;
    const newYPos = YPos ? (YPos + 1) : currentYPos;

    dispatch({
      type: types.SHOW_CONTEXT_MENU, show, contextMenuXPos: newXPos, contextMenuYPos: newYPos
    });
  };
}

export function closeTemplateModalMessage () {
  return (dispatch) => {
    dispatch({ type: types.CLOSE_TEMPLATE_MODAL_MESSAGE });
  };
}

/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { setField } from 'Screens/ManageEditCreate/actions/Actions';
import {
  selectDataField,
  selectRequestField,
  selectResponseField,
  selectWebsiteField,
  selectMethodField,
  selectQueryField,
  selectParamNameField,
  selectParamsField,
  selectStatusCodeField,
  selectIsWebVuln,
  selectPathField
} from 'store/ManageEditCreate/selectors';
import { methodOptions } from 'Screens/Constants';
import MarkdownInput from '../MarkdownInput';
import CheckBox from 'Common/Components/Checkbox/index';
import StringField from 'Common/Components/StringField';
import StandardDropdown from 'Common/Components/StandarDropdown';
import { Wrapper, Row, Field, MethodTitle } from './styled';
class TechnicalDetails extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};

    this.saveField = this.saveField.bind(this);
  }

  saveField (field, value) {
    this.props.setField(field, value);
  }

  render () {
    return (
      <Wrapper>
        <Row>
          <CheckBox checked={ false } onChange={ () => this.saveField('isWebVuln', !this.props.isWebVuln) } state={ this.props.isWebVuln } text={ 'Web Vulnerability' } />
        </Row>
        <Row>
          <Field>
            <MarkdownInput
              title="Data"
              value={ this.props.data }
              onBlur={ (value) => this.saveField('data', value) }
            />
          </Field>
          { this.props.isWebVuln &&
            <Field>
              <MarkdownInput
                title="Query string"
                value={ this.props.query }
                onBlur={ (value) => this.saveField('query', value) }
                plainText
              />
            </Field>
          }
        </Row>
        { this.props.isWebVuln &&
          (
            <>
              <Row>
                <Field>
                  <StringField
                    width="405px"
                    height="38px"
                    title="Website"
                    defaultValue={ this.props.website }
                    onChange={ (value) => this.saveField('website', value) }
                  />
                </Field>
                <Field>
                  <StringField
                    width="405px"
                    height="38px"
                    title="Path"
                    defaultValue={ this.props.path }
                    onChange={ (value) => this.saveField('path', value) }
                  />
                </Field>
              </Row>
              <Row>
                <Field>
                  <MethodTitle>Method</MethodTitle>
                  <StandardDropdown
                    field="Method"
                    defaultValue={ this.props.method }
                    options={ methodOptions }
                    updateValue={ (field, value) => this.saveField('method', value) }
                    placeholder="Select method"
                    width="154px"
                    normal
                  />
                </Field>
                <StringField
                  width="405px"
                  height="38px"
                  title="Status code"
                  defaultValue={ this.props.statusCode }
                  onChange={ (value) => this.saveField('status_code', value) }
                  type="number"
                />
              </Row>
              <Row>
                <Field>
                  <StringField
                    width="405px"
                    height="38px"
                    title="Parameter Name"
                    defaultValue={ this.props.paramName }
                    onChange={ (value) => this.saveField('paramName', value) }
                  />
                </Field>
                <Field>
                  <StringField
                    width="405px"
                    height="38px"
                    title="Parameter"
                    defaultValue={ this.props.params }
                    onChange={ (value) => this.saveField('params', value) }
                  />
                </Field>
              </Row>
              <Row>
                <MarkdownInput
                  title="Request"
                  value={ this.props.request }
                  onBlur={ (value) => this.saveField('request', value) }
                  plainText
                />
                <MarkdownInput
                  title="Response"
                  value={ this.props.response }
                  onBlur={ (value) => this.saveField('response', value) }
                  plainText
                />
              </Row>
            </>)}
      </Wrapper>
    );
  }
}

TechnicalDetails.propTypes = {
  setField: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  setField: (fieldName, value) => {
    dispatch(setField(fieldName, value));
  }
});

const mapStateToProps = (state) => ({
  data: selectDataField(state),
  isWebVuln: selectIsWebVuln(state),
  request: selectRequestField(state),
  response: selectResponseField(state),
  website: selectWebsiteField(state),
  method: selectMethodField(state),
  query: selectQueryField(state),
  paramName: selectParamNameField(state),
  params: selectParamsField(state),
  statusCode: selectStatusCodeField(state),
  path: selectPathField(state)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TechnicalDetails));

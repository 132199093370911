import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import get from 'lodash/get';
import filter from 'lodash/filter';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import w3af from 'Images/tool_logo_w3af.png';
import burp from 'Images/tool_logo_burp.png';
import arachni from 'Images/tool_logo_arachni.png';
import nikto from 'Images/tool_logo_nikto.png';
import wpscan from 'Images/tool_logo_wpscan.png';
import nessus from 'Images/tool_logo_nessus.png';
import nmap from 'Images/tool_logo_nmap.png';
import nuclei from 'Images/tool_logo_nuclei.png';
import openvas from 'Images/tool_logo_openvas.png';
import cli from 'Images/tool_logo_cli.png';

export const selectFields = (state) => get(state, 'agents.table.list.fields', []);

export const selectIsFetching = (state) => get(state, 'agents.isFetching', false);

export const selectUserAgents = (state) => get(state, 'agents.userAgents', []);

export const selectTriggerAgentTools = (state) => get(state, 'agents.triggerAgent.tools', []);

export const selectTriggerAgentId = (state) => get(state, 'agents.triggerAgent.agentId', {});

export const selectTriggerAgentWs = (state) => get(state, 'agents.triggerAgent.workspaces', []);

export const selectTriggerIgnoreInfo = (state) => get(state, 'agents.triggerAgent.ignoreInfo', false);

export const selectTriggerResolveHostname = (state) => get(state, 'agents.triggerAgent.resolveHostname', true);

export const selectTools = () => [
  {
    category: { name: 'Web App Scanners' },
    name: 'arachni',
    title: 'Arachni',
    website: 'https://www.arachni-scanner.com/',
    description: 'Web App Scanner',
    image: arachni,
    params: [{ name_url: true }],
    environmentVariables: [{ name: 'ARACHNI_PATH' }]
  },
  {
    category: { name: 'Web App Scanners' },
    name: 'burp',
    title: 'Burp Scanner',
    website: 'https://portswigger.net/burp/vulnerability-scanner',
    description: 'Web Vulnerability Scanner',
    image: burp,
    params: [{ api_host: true }, { api_key: true }, { target_url: true }, { named_configuration: false }],
    environmentVariables: [{ name: 'BURP_HOST' }, { name: 'BURP_API_KEY' }, { name: 'BURP_API_PULL_INTERVAL' }]
  },
  {
    category: { name: 'Web App Scanners' },
    name: 'nikto2',
    title: 'Nikto',
    description: 'Web Server Scanner',
    image: nikto,
    params: [{ target_url: true }, { target_port: false }],
    environmentVariables: []
  },
  {
    category: { name: 'Web App Scanners' },
    name: 'w3af',
    title: 'w3af',
    website: 'https://w3af.org/',
    description: 'Web App Security Scanner',
    image: w3af,
    params: [{ w3af_target_url: true }],
    environmentVariables: [{ name: 'W3AF_PATH' }]
  },
  {
    category: { name: 'Web App Scanners' },
    name: 'wpscan',
    title: 'WPScan',
    website: 'https://wpscan.com/wordpress-security-scanner',
    description: 'WordPress Security Scanner',
    image: wpscan,
    params: [{ wpscan_target_url: true }],
    environmentVariables: []
  },
  {
    category: { name: 'Web App Scanners' },
    name: 'zap',
    title: 'OWASP ZAP',
    website: 'https://www.zaproxy.org/',
    description: 'Web App Scanner',
    image: cli,
    params: [{ TARGET_URL: true }, { API_KEY: true }],
    environmentVariables: []
  },
  {
    category: { name: 'Network and Vulnerability Scanners' },
    name: 'nessus',
    website: 'https://www.tenable.com/products/nessus',
    title: 'Nessus',
    description: 'Vulnerability Scanner By Tenable',
    image: nessus,
    params: [{ nessus_scan_name: false }, { nessus_url: false }, { nessus_scan_target: true }, { nessus_scan_template: false }],
    environmentVariables: [{ name: 'NESSUS_URL' }, { name: 'NESSUS_USERNAME' }, { name: 'NESSUS_PASSWORD' }]
  },
  {
    category: { name: 'Network and Vulnerability Scanners' },
    name: 'nmap',
    website: 'https://nmap.org',
    title: 'Nmap',
    description: 'Open Source Network Scanner',
    image: nmap,
    params: [{ target: true }, { port_list: false }, { option_sc: false }, { option_sv: false }, { option_pn: false }, { script_timeout: false }, { host_timeout: false }, { top_ports: false }],
    environmentVariables: []
  },
  {
    category: { name: 'Network and Vulnerability Scanners' },
    name: 'nuclei',
    website: 'https://nuclei.projectdiscovery.io/',
    title: 'Nuclei',
    description: 'Vulnerability Scanner',
    image: nuclei,
    params: [{ nuclei_target: true }, { nuclei_exclude: false }],
    environmentVariables: [{ name: 'NUCLEI_TEMPLATES' }]
  },
  {
    category: { name: 'Network and Vulnerability Scanners' },
    name: 'gvm_openvas',
    website: 'https://www.openvas.org/',
    title: 'OpenVAS',
    description: 'VulnerabilityScanner',
    image: openvas,
    params: [{ openvas_user: true }, { openvas_passw: true }, { openvas_host: true }, { openvas_port: true }, { openvas_scan_url: true }, { openvas_scan_id: false }],
    environmentVariables: []
  },
  {
    category: { name: 'Network Tools' },
    name: 'sublist3r',
    title: 'Sublist3r',
    website: 'https://github.com/aboul3la/Sublist3r',
    description: 'Enumerates Subdomains',
    image: cli,
    params: [{ domain: true }],
    environmentVariables: []
  },
  {
    category: { name: 'Pentesting' },
    name: 'crackmapexec',
    title: 'CrackMapExec',
    website: 'https://github.com/byt3bl33d3r/CrackMapExec',
    description: 'Active Directory oriented tool',
    image: cli,
    params: [{ crackmapexec_ip: true }, { crackmapexec_user: false }, { crackmapexec_pass: false }, { crackmapexec_lport: false }, { crackmapexec_lhost: false }],
    environmentVariables: []
  },
  {
    category: { name: 'Report Processor' },
    name: 'report_processor',
    title: 'Report Processor',
    website: 'https://faradaysec.com/',
    description: 'Report uploading tool',
    image: cli,
    params: [{ report_name: true }, { config_tool: false }],
    environmentVariables: [{ name: 'REPORTS_PATH' }]
  }
];

export const selectToolsByCategory = () => {
  const tools = selectTools();
  const groupedTools = groupBy(tools, 'category.name');
  const mappedTools = map(groupedTools, (value, key) => ({ categoryName: key, items: value }));
  return mappedTools;
};

export const selectDraftNewAgentName = (state) => get(state, 'agents.draftNewAgent.name', '');
export const selectDraftNewAgentServer = (state) => get(state, 'agents.draftNewAgent.server', '');
export const selectDraftNewAgentTools = (state) => get(state, 'agents.draftNewAgent.tools', []);

export const selectToolsSelected = (state) => {
  const draftNewAgentTools = selectDraftNewAgentTools(state);
  const toolsSelected = draftNewAgentTools.map((e) => e.name);
  return selectTools().filter((tool) => toolsSelected.indexOf(tool.name) > -1);
};

export const selectToolIsAdded = (state, tool) => {
  const draftNewAgentTools = selectDraftNewAgentTools(state);
  const toolsSelected = draftNewAgentTools.map((e) => e.name);
  return toolsSelected.indexOf(tool) > -1;
};

export const selectToolHasEnvVars = (tool) => {
  const toolSelected = head(filter(selectTools(), { name: tool }));
  return toolSelected ? toolSelected.environmentVariables.length > 0 : false;
};

export const selectToolHasBeenConfigured = (state, tool) => {
  const draftNewAgentTools = selectDraftNewAgentTools(state);
  const toolSelected = head(filter(draftNewAgentTools, { name: tool }));
  return toolSelected ? !isEmpty(toolSelected.variables) : false;
};

export const selectToolsVariables = (state, tool) => {
  const draftNewAgentTools = selectDraftNewAgentTools(state);
  const toolSelected = head(filter(draftNewAgentTools, { name: tool }));
  return get(toolSelected, 'variables', {});
};

export const selectToolError = (state, tool) => {
  const errors = get(state, 'agents.errors', {});
  return get(errors, tool, false);
};

export const selectAgentError = (state) => get(state, 'agents.error', false);

export const selectAgentTypes = (state) => get(state, 'agents.agentTypes', {});

export const selectToken = (state) => get(state, 'agents.token', null);

export const selectTokenExpiration = (state) => get(state, 'agents.tokenExpiresIn', 0);

import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectShowInactive, selectWorkspacesOrdered, selectWorkspacesSelected
} from 'store/Workspace/selectors';
import some from 'lodash/some';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import WorkspaceItem from './components/WorkspaceItem';
import { Wrapper } from './styled';

const GridView = () => {
  const workspaceList = useSelector(selectWorkspacesOrdered);
  const workspacesSelected = useSelector(selectWorkspacesSelected);
  const showInactive = useSelector(selectShowInactive);
  const currentWorkspace = useSelector(selectCurrentWorkspace);

  return (
    <Wrapper>
      { workspaceList.filter((ws) => ws.active).map((ws) => <WorkspaceItem key={ ws.id } ws={ ws } selected={ some(workspacesSelected, { name: ws.name }) } />) }
      { showInactive && workspaceList.filter((ws) => ws.name !== currentWorkspace && !ws.active).map((ws) => <WorkspaceItem key={ ws.id } ws={ ws } selected={ some(workspacesSelected, { name: ws.name }) } />) }
    </Wrapper>
  );
};

export default GridView;

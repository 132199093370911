import React, { useState, useEffect } from 'react';
import Layout from 'Screens/Dashboard/components/Layout';
import ImportFileCreationModal from 'Screens/ManageEditCreate/components/ImportFileCreationModal';
import { getVulnsCount } from 'Screens/Dashboard/actions/Actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';

import EmptyVulns from 'Common/Components/EmptyVulns';
import emptyFeed from 'Images/empty-feed.png';
import ManualCreationModal from 'Screens/ManageEditCreate/components/ManualCreationModal';

import { selectTotalVulns } from 'store/Workspace/selectors';
import { StyledCol, Wrapper } from './Dashboard/styled';

const DashboardLayout = () => {
  const dispatch = useDispatch();
  const props = useSelector((state) => ({
    dashboard: state.dashboard,
    vulns: state.dashboard.tools[0].data,
    workspaceSelected: selectCurrentWorkspace(state),
    reloadVulnerabilities: state.dashboard.reloadVulnerabilities,
    faraday: state.faraday,
    totalVulns: selectTotalVulns(state),
    isFetching: state.dashboard.isFetching
  }));
  const {
    workspaceSelected, reloadVulnerabilities
  } = props;

  useEffect(() => {
    dispatch(getVulnsCount());
  }, [workspaceSelected, reloadVulnerabilities, dispatch]);

  const [showModalUploadVulns, toggleModalUploadVulns] = useState(false);
  const [showModalCreateVuln, toggleModalCreateVuln] = useState(false);

  if (props.totalVulns === 0 && !props.isFetching) {
    return (
      <>
        <EmptyVulns image={ emptyFeed } toggleModalUploadVulns={ () => toggleModalUploadVulns(true) } toggleModalCreateVuln={ () => toggleModalCreateVuln(true) } />
        <ImportFileCreationModal show={ showModalUploadVulns } handleClose={ () => toggleModalUploadVulns(false) } />
        <ManualCreationModal show={ showModalCreateVuln } handleClose={ () => toggleModalCreateVuln(false) } />
      </>
    );
  }
  const { tools } = props.dashboard;

  return (
    <Wrapper>
      <StyledCol>
        { tools.filter((t) => t.render).length > 0 ? <Layout tools={ tools } /> : null }
      </StyledCol>
    </Wrapper>
  );
};

export default DashboardLayout;
